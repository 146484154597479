body {
  --btn-primary: var(--blue-600);
  --primary: var(--blue-600);
}

.btn-primary:focus {
  box-shadow: none !important;
  outline: none !important;
}

.btn-primary:not(:disabled):not(.disabled):active, .btn-primary:not(:disabled):not(.disabled).active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: var(--blue-600);
  border-color: var(--blue-600);
}

.btn.btn-primary:active {
  color: #ffffff !important;
  background-color: var(--blue-800) !important;
}

.page-card {
  max-width: 360px;
  padding: 15px;
  margin: 70px auto;
  border-radius: 8px;
  background-color: #f9f4e9;
  box-shadow: var(--shadow-base);
}
.page-card .page-card-head {
  border-radius: 7px 7px 0px 0px;
  font-size: 16px;
  font-weight: 600;
}

.page-card .page-card-head .indicator {
  color: var(--primary);
  font-size: 16px;
  font-weight: 700;
}

#page-login .for-login, #page-login .for-forgot, #page-login .for-signup, #page-login .for-email-login, #page-login .for-login-with-email-link {
  padding: 0 !important;
  display: none;
}

#page-login .page-card .page-card-head {
  background: transparent;
  border: none !important;
  text-align: center;
}
#page-login .page-card .text-center.sign-up-message {
  font-weight: 600;
  font-size: 14px;
  position: relative;
  top: 15px;
}
#page-login .page-card .login-content-bg {
  background: #f3f8fa;
  width: 50%;
  margin: -20px 20px -20px -20px;
  border-radius: 10px 0px 0px 10px;
  position: relative;
}
#page-login .page-card .login-content-bg .app-logo {
  max-height: 40px;
  margin: 20px;
}
#page-login .page-card .login-content-bg img.app-login-bg {
  width: 90%;
  margin: 50px auto;
  display: block;
}
#page-login .page-card .login-content-bg .login-copyrights {
  position: absolute;
  bottom: 20px;
  font-weight: 600;
  font-size: 13px;
  left: 0px;
  right: 0px;
  text-align: center;
}
#page-login .page-card .login-content-form {
  width: 50%;
  padding: 50px 20px;
}
#page-login .page-card .login-content-form .btn-login, #page-login .page-card .login-content-form .btn-signup, #page-login .page-card .login-content-form .btn-forgot {
  height: 50px !important;
  border-radius: 25px !important;
  font-size: 16px;
}
#page-login .page-card .login-content-form .forgot-password-message {
  margin-top: 15px;
}
#page-login .page-card .login-content-form span.toggle-password.text-muted {
  top: 14px !important;
  right: 14px !important;
  opacity: 0.7;
  transition: all 0.2s ease-in-out;
}
#page-login .page-card .login-content-form span.toggle-password.text-muted:hover {
  opacity: 1;
}

.for-login .page-card form, .for-forgot .page-card form, .for-signup .page-card form, .for-email-login .page-card form {
  max-width: 100%;
  margin: 0 auto;
}

.for-login .page-card, .for-forgot .page-card, .for-signup .page-card, .for-email-login .page-card, .for-login-with-email-link .page-card {
  max-width: 480px;
  padding: 20px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);
}

.for-login .page-card-head img, .for-forgot .page-card-head img, .for-signup .page-card-head img, .for-email-login .page-card-head img {
  max-height: 62px !important;
  margin-bottom: 25px;
}

.form-label {
  cursor: pointer;
}

#page-login .form-control {
  background: #ffffff !important;
  border: 1px solid #D8D6DE !important;
  height: 50px !important;
  padding-left: 40px;
  transition: all 0.2s ease-in-out;
}
#page-login .form-control:focus.is-valid, #page-login .form-control:focus {
  box-shadow: 0 3px 10px 0 rgba(97, 163, 243, 0.4) !important;
  border-color: var(--primary) !important;
  padding-left: 43px;
  outline: 0;
}

.for-login .page-card .page-card-body .forgot-password-message > *, .for-forgot .page-card .page-card-body .forgot-password-message > *, .for-signup .page-card .page-card-body .forgot-password-message > *, .for-email-login .page-card .page-card-body .forgot-password-message > * {
  font-size: 14px !important;
}
.for-login .page-card .page-card-body .forgot-password-message > *:hover, .for-forgot .page-card .page-card-body .forgot-password-message > *:hover, .for-signup .page-card .page-card-body .forgot-password-message > *:hover, .for-email-login .page-card .page-card-body .forgot-password-message > *:hover {
  color: var(--primary);
}

.for-login .page-card, .for-forgot .page-card, .for-login-with-email-link .page-card, .for-signup .page-card, .for-email-login .page-card {
  box-shadow: 0px 1px 15px -3px rgba(0, 0, 0, 0.368627451);
  font-size: 16px;
}

#page-login h2 {
  margin-top: 0;
  font-weight: 700;
}
#page-login .page_content {
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 300;
}
#page-login .page_content .page-card {
  position: relative;
  z-index: 315;
}
#page-login .page_content .login-page-slideshow-container {
  position: fixed;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 310;
}
#page-login .page_content .login-page-slideshow-container .login-page-slideshow-item {
  height: 100vh;
}
#page-login .page_content .login-page-slideshow-container .login-page-slideshow-item > img {
  height: 100%;
  width: 100%;
  max-width: initial;
  display: block;
}
#page-login .page_content .login-page-slideshow-container:after {
  background: rgba(0, 0, 0, 0) linear-gradient(to bottom, rgba(0, 0, 0, 0) 0px, rgba(0, 0, 0, 0.9) 100%) repeat scroll 0 0;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  bottom: 0px;
  display: block;
  content: "";
  z-index: 312;
}
#page-login .page_content.full-background {
  top: 0px;
}
#page-login .page_content.min-background {
  top: 74px;
}
#page-login .page_content.min-background .login-page-slideshow-container {
  top: 74px;
}
#page-login .page_content.widget-background-transparent .page-card .login-content-bg {
  background: transparent;
}
#page-login .page_content.widget-background-transparent .page-card {
  padding-top: 45px !important;
  padding-bottom: 45px !important;
  background-color: rgba(255, 255, 255, 0.75) !important;
  border: 2px solid rgba(255, 255, 255, 0.75) !important;
  box-shadow: 0px 2px 14px -6px #000 !important;
  color: #000000 !important;
  position: relative;
  z-index: 315;
}
#page-login .page_content.widget-background-transparent .page-card .form-control {
  border: 1px solid #8d8d8d !important;
}
#page-login .page_content.widget-background-transparent .page-card .form-control:focus {
  border-color: var(--primary) !important;
}
#page-login .page_content.widget-background-transparent .page-card > a {
  color: #000;
}

.for-email-login .page-card .page-card-body .field-icon, .for-forgot .page-card .page-card-body .field-icon, .for-login .page-card .page-card-body .field-icon, .for-signup .page-card .page-card-body .field-icon {
  left: 14px !important;
  top: 15px !important;
}

.for-login .page-card .page-card-body .email-field .toggle-password, .for-login .page-card .page-card-body .password-field .toggle-password, .for-forgot .page-card .page-card-body .email-field .toggle-password, .for-forgot .page-card .page-card-body .password-field .toggle-password, .for-login-with-email-link .page-card .page-card-body .email-field .toggle-password, .for-login-with-email-link .page-card .page-card-body .password-field .toggle-password, .for-signup .page-card .page-card-body .email-field .toggle-password, .for-signup .page-card .page-card-body .password-field .toggle-password, .for-email-login .page-card .page-card-body .email-field .toggle-password, .for-email-login .page-card .page-card-body .password-field .toggle-password {
  right: 12px !important;
  top: 12px !important;
}

.for-login .sign-up-message a, .for-forgot .sign-up-message a, .for-login-with-email-link .sign-up-message a, .for-signup .sign-up-message a, .for-email-login .sign-up-message a {
  font-size: 16px !important;
  font-weight: 600 !important;
}

[frappe-session-status=logged-out][data-path=message] .navbar {
  display: none;
}
[frappe-session-status=logged-out][data-path=message] .page-card {
  max-width: 500px;
  border-radius: 7px;
  overflow: hidden;
}
[frappe-session-status=logged-out][data-path=message] .page-card .page-card-head .indicator {
  font-size: 18px;
}
[frappe-session-status=logged-out][data-path=message] .page-card .page-card-head .indicator:before {
  display: none;
}
[frappe-session-status=logged-out][data-path=message] .page-card .page-card-body > p {
  font-size: 21px;
  font-weight: 600;
  text-align: center;
}
[frappe-session-status=logged-out][data-path=message] .page-card .page-card-body > p:before {
  display: block;
  text-align: center;
  font-size: 55px;
  font-family: "Font Awesome 5 Pro";
  font-weight: 300;
  content: "\f502";
}
[frappe-session-status=logged-out][data-path=message] .page-card .page-card-body > div .btn {
  display: block;
  margin: 15px 0px 0px 0px;
  height: 45px;
  line-height: 45px;
  padding: 0;
  font-size: 16px;
  font-weight: 700;
  border-radius: 31px;
}

.for-login .page-card .page-card-body .social-logins .social-login-buttons, .for-forgot .page-card .page-card-body .social-logins .social-login-buttons, .for-login-with-email-link .page-card .page-card-body .social-logins .social-login-buttons, .for-signup .page-card .page-card-body .social-logins .social-login-buttons, .for-email-login .page-card .page-card-body .social-logins .social-login-buttons {
  margin-top: var(--margin-md);
  margin-bottom: 10px;
}

#page-login .page_content .page-card {
  background: #fff;
}

[data-path=login] {
  background: #fafafa;
}
[data-path=login] .page-card-head {
  padding: 0 !important;
}

@media (max-width: 600px) {
  .for-login, .for-forgot, .for-signup, .for-email-login {
    margin: 0px;
  }
  .for-login .page-card-head, .for-forgot .page-card-head, .for-signup .page-card-head, .for-email-login .page-card-head {
    margin-bottom: 20px;
  }
  #page-login .for-login, #page-login .for-forgot, #page-login .for-signup, #page-login .for-email-login {
    padding: 30px !important;
  }
  #page-login .page-card .login-content-bg {
    display: none;
  }
  #page-login .page-card .login-content-form {
    width: 100%;
    padding: 0px 10px 30px 10px;
  }
}